import AMERICAN_EXPRESS from 'images/ic/24/american_express.svg';
import JCB from 'images/ic/24/jcb.svg';
import MASTERCARD from 'images/ic/24/mastercard.svg';
import UNION_PAY from 'images/ic/24/unionpay.svg';
import MIR from 'images/ic/24/mir.svg';
import VISA from 'images/ic/24/visa.svg';
import { SavedCard } from 'domains/savedCards';
import style from './Card.module.css';
import clsx from 'clsx';
import { FERadioButton } from 'ui-kit/components';
import { IMG_IC_24_CREDIT_CARD } from 'ui-kit/images';

const GET_CARD_TYPE_ICON: Record<SavedCard['type'], string> = {
  AMERICAN_EXPRESS: AMERICAN_EXPRESS,
  JCB: JCB,
  MASTERCARD: MASTERCARD,
  UNION_PAY: UNION_PAY,
  MIR: MIR,
  VISA: VISA,
  OTHER: IMG_IC_24_CREDIT_CARD,
};

export const Card = ({ card, isActive, onClick }: Props) => {
  return (
    <div
      is-active={isActive.toString()}
      className={style.card}
      onClick={onClick}
    >
      <img className={style.icon} src={GET_CARD_TYPE_ICON[card.type]} alt='' />
      <div className={style.radioContainer}>
        <FERadioButton active={isActive} />
      </div>
      <p className={clsx('text-2', style.label)}>{`**** ${card.lastFour}`}</p>
    </div>
  );
};

type Props = {
  card: SavedCard;
  onClick: () => void;
  isActive: boolean;
};
