import clsx from 'clsx';
import { CatalogCategory } from 'domains/catalog';
import { CurrencySymbol, OrderType } from 'models';
import { Cart, Ordering } from './components';
import { ContentProps } from './props';
import style from './style.module.css';

const Content = ({
  onClose,
  dishes,
  constructors,
  cutleries,
  price,
  warning,
  isValidating,
  timeTo,
  urgent,
  promoCode,
  onChangePromoCode,
  onChangeTime,
  stage,
  promotion,
  promotionProduct,
  problems,
  type,
  timeIntervals,
  address,
  restaurant,
  onChangeStage,
  name,
  onChangeName,
  phone,
  onChangePhone,
  comment,
  onChangeComment,
  paymentType,
  onChangePaymentType,
  bonusAmount,
  onChangeBonusAmount,
  changeFrom,
  onChangeChangeFrom,
  onChangeConstructorQuantity,
  onChangeCutleryQuantity,
  onChangeDishQuantity,
  onDeleteConstructor,
  onDeleteCutlery,
  onDeleteDish,
  paymentTypes,
  user,
  bonusAccount,
  onCreateOrder,
  navigateFunction,
  onChangePromotionProduct,
  cartographyLink,
  deliveryZone,
  hasCriticalCartError,
  title,
  onArrowBackClick,
  isDesktop,
  longDeliveryWarning,
  createOrderData,
  features,
  currency,
  countries,
  country,
  onClearCart,
  changeCountryCode,
  categories,
  onChangeOrderType,
  availableDeliveryTypes,
  paymentCardId,
  onChangePaymentCardId,
}: ContentProps & {
  paymentCardId: string | null;
  currency: CurrencySymbol;
  categories: CatalogCategory[];
  onChangeOrderType: (orderType: OrderType) => void;
  availableDeliveryTypes: OrderType[];
}) => {
  return (
    <div
      className={clsx(style.root, stage === 'ORDERING' && style.root__ordering)}
    >
      {stage === 'CART' ? (
        <Cart
          {...{
            availableDeliveryTypes,
            onChangeOrderType,
            categories,
            onClearCart,
            currency,
            longDeliveryWarning,
            isDesktop,
            title,
            deliveryZone,
            hasCriticalCartError,
            cartographyLink,
            onClose,
            dishes,
            constructors,
            cutleries,
            price,
            warning,
            isValidating,
            timeTo,
            urgent,
            promoCode,
            onChangePromoCode,
            onChangeTime,
            stage,
            promotion,
            promotionProduct,
            problems,
            type,
            timeIntervals,
            address,
            restaurant,
            onChangeStage,
            onChangeConstructorQuantity,
            onChangeCutleryQuantity,
            onChangeDishQuantity,
            onDeleteConstructor,
            onDeleteCutlery,
            onDeleteDish,
            onChangePromotionProduct,
          }}
        />
      ) : (
        <Ordering
          {...{
            type,
            onChangePaymentCardId,
            paymentCardId,
            changeCountryCode,
            countries,
            country,
            currency,
            features,
            createOrderData,
            onClose,
            title,
            isDesktop,
            onArrowBackClick,
            orderType: type,
            name,
            onChangeName,
            phone,
            onChangePhone,
            comment,
            onChangeComment,
            paymentType,
            onChangePaymentType,
            bonusAmount,
            onChangeBonusAmount,
            changeFrom,
            onChangeChangeFrom,
            stage,
            onChangeStage,
            paymentTypes,
            user,
            bonusAccount,
            price,
            onCreateOrder,
            isValidating,
            navigateFunction,
          }}
        />
      )}
    </div>
  );
};

export default Content;
