import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import style from './QRCodeContainer.module.css';

const QRCode = require('qrcodejs2');

export const QRCodeContainer = ({
  phone,
  isEnoughCustomerData,
  isLoyaltyProgramAvailable,
}: Props) => {
  const { t } = useTranslation();

  const qrCodeContainerRef = useRef<HTMLDivElement | null>(null);

  const qrCodeInstanceRef = useRef();

  useEffect(() => {
    if (!qrCodeInstanceRef.current && phone) {
      qrCodeInstanceRef.current = new QRCode(qrCodeContainerRef.current, {
        text: phone,
        width: 60 - 14,
        height: 60 - 14,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      });
    }

    return () => {
      //@ts-expect-error
      qrCodeInstanceRef.current && qrCodeInstanceRef.current.clear();
    };
  }, [phone]);

  return (
    <div className={style.container}>
      {!isEnoughCustomerData && (
        <p className={clsx('subhead', style.text)}>
          {t('profile.BonusMessage')}
        </p>
      )}

      {isEnoughCustomerData && (
        <p className={clsx('subhead', style.text)}>{t('profile.QRTitle')}</p>
      )}

      {!isEnoughCustomerData && (
        <p className={clsx('caption-1', style.text, style.message)}>
          {t('Заполни данные профиля, для того чтобы начать копить бонусы')}
        </p>
      )}

      {!isLoyaltyProgramAvailable && isEnoughCustomerData && (
        <p className={clsx('caption-1', style.text, style.message)}>
          {t('profile.QR-desctiption')}
        </p>
      )}

      {isEnoughCustomerData && isLoyaltyProgramAvailable && (
        <p className={clsx('caption-1', style.text, style.message)}>
          {t('profile.QR-desctiption')}
        </p>
      )}

      <div ref={qrCodeContainerRef} className={style.codeContainer} />
    </div>
  );
};

type Props = {
  phone: string;
  isEnoughCustomerData: boolean;
  isLoyaltyProgramAvailable: boolean;
};
