import FEHeaderProps from './props';
import style from './style.module.css';
import { FEBar } from 'components';
import { Confirmation, Menu } from './components';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {useLocation} from "react-router-dom";

const FEMobileHeader = ({
  orderType,
  address,
  restaurant,
  bonusAccount,
  onAddressBarClick,
  onCloseConfirmation,
  onDeclineConfirmation,
  isOpenConfirmation,
}: FEHeaderProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  console.log(location.pathname === '/profile' || "/dostavka-i-oplata")

  return (
    <div className={clsx(style.dataSidePadding, style.dataSide, location.pathname === '/profile' || location.pathname === "/dostavka-i-oplata" && style.dataSideDisplayNone)}>
      <div className={style.addressContainer}>
        <FEBar
          onClick={onAddressBarClick}
          label={
            orderType === 'COURIER'
              ? t('cartography.header.deliveryLabel')
              : t('cartography.header.pickupLabel')
          }
          value={orderType === 'COURIER' ? address : restaurant}
          placeholder={t('cartography.header.placeholder')}
          theme='outline'
          color={'white'}
        />

        <Menu bonusAccount={bonusAccount} />

        <Confirmation
          value={orderType === 'COURIER' ? address : restaurant}
          open={isOpenConfirmation}
          onClose={onCloseConfirmation}
          onDeclineConfirmation={onDeclineConfirmation}
        />
      </div>
    </div>
  );
};

export default FEMobileHeader;
export type { FEHeaderProps };
