import { MOBILE_WIDTH } from 'const';
import {
  $promotionProduct,
  $timeTo,
  $urgent,
  repeatOrderFx,
  $promoCode,
  $isOrderRepeating,
} from 'domains/cart';
import {
  $type,
  $address,
  $restaurant,
  $deliveryZone,
  $currency,
} from 'domains/cartography';
import { $catalogId } from 'domains/catalog';
import {
  // $isPendingOrders,
  $orders,
  $user,
  // getOrdersList,
  Order,
  ordersPageGate,
  getOrdersFx,
} from 'domains/profile';
import { useGate, useUnit } from 'effector-react';
import { useLinks } from 'hooks/router';
import { AuthClient } from 'api/base';
import React, { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FEBottomSheet, FEDialog, FESpinner } from 'components';
import { useMediaQuery } from 'ui-kit/hooks';
import { Content } from './components';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';

const OrdersPage = ({ open }: { open: boolean }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(`(min-width: ${MOBILE_WIDTH}px)`);
  const navigate = useNavigate();
  const location = useLocation();

  const [isPaginationPending, setIsPaginationPending] = useState(false);
  const [lastResponseItemsCount, setLastResponseItemsCount] =
    useState<number>();

  const goBack = () => {
    const isDefault = location.key === 'default';

    if (isDefault) {
      return navigate('/', { replace: true });
    }
    return navigate(-1);
  };

  useGate(ordersPageGate);

  const [
    orders,
    // isPendingOrders,
    catalogId,
    promoCode,
    promotionProduct,
    type,
    address,
    restaurant,
    user,
    timeTo,
    urgent,
    deliveryZone,
    isOrderRepeating,
    onRepeatOrder,
    currency,
    handleGetOrders,
  ] = useUnit([
    $orders,
    // $isPendingOrders,
    $catalogId,
    $promoCode,
    $promotionProduct,
    $type,
    $address,
    $restaurant,
    $user,
    $timeTo,
    $urgent,
    $deliveryZone,
    $isOrderRepeating,
    repeatOrderFx,
    $currency,
    getOrdersFx,
    // getOrdersList,
  ]);

  const { authLink, cartLink } = useLinks();

  React.useEffect(() => {
    if (AuthClient.isAuth === false) {
      navigate(authLink, { replace: true });
    }
  }, [authLink]);

  const handleRepeatOrder = (order: Order) =>
    catalogId &&
    onRepeatOrder({
      order,
      catalogId,
      promoCode,
      promotionProduct,
      type,
      address,
      restaurant,
      user,
      timeTo,
      urgent,
      deliveryZone,
    }).then(() => navigate(cartLink, { replace: true }));

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollHeight, scrollTop } = e.currentTarget;
    const orderHeight = 327;

    if (
      scrollHeight - scrollTop < orderHeight * 3 &&
      !isPaginationPending &&
      lastResponseItemsCount !== 0
    ) {
      setIsPaginationPending(true);
      handleGetOrders({ limit: 15, offset: orders.length })
        .then((res) => setLastResponseItemsCount(res.length))
        .finally(() => setIsPaginationPending(false));
    }
  };

  return isDesktop ? (
    <>
      <FEDialog
        open={open}
        showCloseButton={!isOrderRepeating}
        closeOnOverlayClick={!isOrderRepeating}
        onClose={goBack}
        render={({ onClose }) => (
          <>
            <div onScroll={handleScroll} className={style.root}>
              <div className={style.dialogHeader}>
                <h3 className='title'>{t('orders.title')}</h3>
                {/* <button
                  className={clsx('button-icon-24', style.iconButton)}
                  onClick={() => {}}
                /> */}
              </div>
              <Content
                {...{
                  currency,
                  pendingList: isPaginationPending,
                  onClose,
                  orders,
                  isPendingOrders: false,
                  onRepeatOrder: ({ order }) => handleRepeatOrder(order),
                }}
              />
            </div>
            {isOrderRepeating && (
              <div className={style.spinnerContainer}>
                <FESpinner />
              </div>
            )}
          </>
        )}
      />
    </>
  ) : (
    <>
      <FEBottomSheet
        open={open}
        closeOnOverlayClick={!isOrderRepeating}
        onClose={goBack}
        render={({ onClose }) => (
          <>
            <div onScroll={handleScroll} className={style.root}>
              <div className={style.bottomSheetHeader}>
                <div className={style.side}>
                  <h3 className='title'>{t('orders.title')}</h3>
                  {/* <button
                    className={clsx('button-icon-24', style.iconButton)}
                    onClick={() => {}}
                  /> */}
                </div>
                <button
                  tabIndex={0}
                  className='closeButton-mobile'
                  onClick={(e) => {
                    onClose();
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              <Content
                {...{
                  currency,
                  pendingList: isPaginationPending,
                  onClose,
                  orders,
                  isPendingOrders: false,
                  onRepeatOrder: ({ order }) => handleRepeatOrder(order),
                }}
              />
            </div>
            {isOrderRepeating && (
              <div className={style.spinnerContainer}>
                <FESpinner />
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default OrdersPage;
