import React from 'react';
import { IMG_IC_24_PROFILE_NEW } from 'images';
import style from './style.module.css';
import { PROFILE_PATHNAME } from 'const';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const Menu = ({ bonusAccount }: any) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={style.root} ref={ref}>
      <button
        className={style.openButton}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          window.scrollTo({ top: 0, behavior: 'smooth' });
          navigate(PROFILE_PATHNAME);
        }}
      >
        <div className={style.profileContainer}>
          {bonusAccount && (
            <p className={style.bonusLabel}>
              {bonusAccount ? bonusAccount.balance : 0}
            </p>
          )}
          <img
            src={IMG_IC_24_PROFILE_NEW}
            alt=''
            className={style.profileImg}
          />
          <p>{t('Профиль')}</p>
        </div>
      </button>
    </div>
  );
};

export default Menu;
