import {
  Currency,
  CurrencySymbol,
  Language,
  Measure,
  PaymentType,
  PaymentTypeInList,
} from 'models';
import {
  IMG_IC_24_CASH,
  IMG_IC_24_CREDIT_CARD,
  IMG_IC_24_CARD,
  IMG_IC_24_SBP,
} from 'images';

export const INITIAL_LANGUAGE = 'ru' as Language;

export const DESKTOP_WIDTH = 1024;
export const MOBILE_WIDTH = 500;
export const ANIMATION_DELAY = 200;
export const MOBILE_MENU_WIDTH = 600;

export const BASE_URL = 'https://consumers.feat.first.ftl-dev.ru';

export const GET_MEASURE: Record<Measure | 'KILOCALORIES', string> = {
  LITER: 'measures.l',
  GRAM: 'measures.gr',
  PIECE: 'measures.pcs',
  MILLILITER: 'measures.ml',
  KILOCALORIES: 'measures.kcal',
};

export const GET_PAYMENT_TYPE: Record<PaymentTypeInList, string> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL',
  SBP: 'СБП',
};
export const GET_ALL_PAYMENT_TYPE: Record<
  Exclude<PaymentType, 'SAVED'>,
  string
> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL',
  BONUS: 'paymentTypes.BONUS',
  SBERPAY: 'paymentTypes.SBERPAY',
  SBP: 'СБП',
};

export const GET_COURIER_PAYMENT_TYPE: Record<PaymentTypeInList, string> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL_DELIVERY',
  SBP: 'СБП',
};
export const GET_ALL_COURIER_PAYMENT_TYPE: Record<
  Exclude<PaymentType, 'SAVED'>,
  string
> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL_DELIVERY',
  BONUS: 'paymentTypes.BONUS',
  SBERPAY: 'paymentTypes.SBERPAY',
  SBP: 'СБП',
};

export const GET_PAYMENT_TYPE_IMAGE: Record<PaymentTypeInList, string> = {
  CARD: IMG_IC_24_CREDIT_CARD,
  CASH: IMG_IC_24_CASH,
  TERMINAL: IMG_IC_24_CARD,
  SBP: IMG_IC_24_SBP,
};

export const GET_PARAMS = {
  layout: 'layout',
  page: 'page',
  orderId: 'orderId',
  productId: 'productId',
  restaurant: 'restaurant',
};

export const GET_ENUMS = {
  page: {
    cartography: 'cartography',
    auth: 'auth',
    editProfile: 'edit-profile',
    orders: 'orders',
    cart: 'cart',
    bonus: 'bonus',
  },
};

export const GET_CURRENCY_SYMBOL: Record<Currency, CurrencySymbol> = {
  AED: 'AED',
  EUR: '₽',
  RUB: '₽',
  USD: '₽',
};

export const KEY_CODE = {
  backspace: 'Backspace',
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
};

export const VOLGOGRAD_GEOPOSITION = { lat: 48.7194, lng: 44.5018 };
export const OFFICE_LOCATION = {
  lat: 48.711557,
  lng: 44.530178,
};

export const URL = 'feat.ftl-dev.ru';

export const PORT = '3000';

export const PROMOTIONS_PATHNAME = 'promotions';
export const LEGAL_INFORMATION_PATHNAME = 'legal-information';
export const PROFILE_PATHNAME = 'profile';
export const SAVED_CARDS_PATHNAME = 'saved-cards';
export const DELIVERY_AND_PAYMENTS_PATHNAME = 'dostavka-i-oplata';
export const RESTAURANTS_PATHNAME = 'restaurants';
export const CONTACTS_PATHNAME = 'contacts';

// export const TT_LINK = process.env.REACT_APP_LINK_TIKTOK;
// export const TG_LINK = process.env.REACT_APP_LINK_TELEGRAM;
// export const VK_LINK = process.env.REACT_APP_LINK_VK;
// export const YT_LINK = process.env.REACT_APP_LINK_YOUTUBE;
// export const OK_LINK = process.env.REACT_APP_LINK_OK;

// export const VACANCIES_LINK = process.env.REACT_APP_LINK_VACANCY;
// export const FRANCHISE_REQUEST_LINK = process.env.REACT_APP_LINK_FRANCHISE;

export const APP_STORE_LINK = process.env.REACT_APP_LINK_APPLE;
export const GOOGLE_PLAY_LINK = process.env.REACT_APP_LINK_ANDROID;
export const APP_GALLERY_LINK = process.env.REACT_APP_LINK_HUAWEI;

export const NEW_YEAR_WISHES_LINK =
  'https://redirect.appmetrica.yandex.com/serve/1181493233478278775';

export const LOYALTY_PROGRAM_LINK =
  'https://docs.google.com/document/d/1GsrmB9mLjyvyz2uCIhFwoIXwFJ7vI3Jc/edit';
export const OFFER_LINK = '';
export const PERSONAL_DATA_LINK = '';
export const TERMS_LINK = '';

export const HOTLINE_PHONE_NUMBER = '8 (800) 550-30-30';

export const FEEDBACK_EMAIL = 'ask@sushivesla.team';

export const JOB_EMAIL = 'rabota@sushivesla.su';

export const DEVELOPMENT_EMAIL = 'development@sushivesla.team';
export const DEVELOPMENT_PHONE_NUMBER = '+7 (987) 658-11-19';

export const OFFICE_EMAIL = 'office@sushivesla.team';

export const SUPPLY_EMAIL = 'snab@sushivesla.team';
export const SUPPLY_PHONE_NUMBER = '+7 (927) 061-00-31';

export const MEDIA_EMAIL = 'press@sushivesla.team';

export const ADVERTISING_EMAIL = 'marketing@sushiveslarf.com';

export const FRANCHISE_PHONE_NUMBER = '+7 (937) 533-38-17';
